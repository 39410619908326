<template>
  <div class="page-view-box page-max">
    <el-config-provider :locale="locale">
      <router-view />
    </el-config-provider>
  
  </div>


</template>
<script setup>
// 中文包
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import { ref } from 'vue';

const locale = ref(zhCn)
</script>


<style>
@import "assets/font/qweather-icons.css";

* {
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  /*系统默认菜单被禁用*/
  -webkit-user-select: none;
  /*webkit浏览器*/
  -khtml-user-select: none;
  /*早期浏览器*/
  -moz-user-select: none;
  /*火狐*/
  -ms-user-select: none;
  /*IE10*/
  user-select: none;
}

/* swiper轮播点 */
#app .swiper-pagination-bullet {
  width: .125rem !important;
  height: .125rem !important;
  background: #283956 !important;
}

/* 谷歌 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* 火狐 */
input {
  -moz-appearance: textfield;
}

.particles {
  width: 100%;
  height: 100%;
  position: fixed;
}

.page-view-box {
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url('./assets/images/bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow-y: scroll;
}

.page-view-box::-webkit-scrollbar {
  width: 0 !important;
}

body .van-toast {
  font-size: .25rem !important;
  padding: .25rem .4rem !important;
  background: rgba(125, 125, 125, 1) !important;
  letter-spacing: .013rem;
  border-radius: 200px !important;
  bottom: 8%;
}

body .van-overlay {
  z-index: 999;
}
</style>
